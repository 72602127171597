
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import RegistrationForm from "../components/authentication/RegistrationForm.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { UFFormValidationMessage } from "@/utils/literals/forms";

@Component({
  name: "RegistrationPage",
  components: { RegistrationForm },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
  methods: {
    ...mapActions("user", ["createUser"]),
  },
})
export default class RegistrationPage extends Vue {
  @Prop({ type: String, required: false, default: null })
  token: string | undefined;

  email = "";
  disableForm = false;

  sendRegistrationEmail(): void {
    this.$v.email.$touch();
    this.disableForm = true;
    this.$store.dispatch("getRegistrationEmail", {
      email: this.email,
      errorNext: () => {
        this.disableForm = false;
      },
    });
  }

  get emailErrors(): string[] {
    const errors = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push(this.getUFErrorMessage("invalidEmail"));
    !this.$v.email.required && errors.push(this.getUFErrorMessage("required"));
    return errors;
  }

  get isSubmitDisable(): boolean {
    return this.$v.$invalid || this.disableForm;
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  getUFErrorMessage(key: string): string {
    return UFFormValidationMessage[key] || "";
  }
}
